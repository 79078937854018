import getConfig from "next/config";

const getEnv = (key: string, defaultValue: unknown = ""): string => {
  const { publicRuntimeConfig = {} } = getConfig() || {};

  if (key.startsWith("NEXT_PUBLIC") && key in publicRuntimeConfig) {
    return publicRuntimeConfig[key];
  }

  if (key in process.env && process.env[key]) {
    return process.env[key] as string;
  }

  return String(defaultValue);
};

const parseInterval = (key: string, defaultValue: number) =>
  (parseInt(getEnv(key, defaultValue)) ?? defaultValue) * 60 * 1000;

const config = {
  authSecret: getEnv("AUTH_SECRET") + "9",
  mainServerUrl: getEnv("MAIN_SERVER_URL", "https://app.scottie.ai/api"),
  dateIntervalForMergingProjects: 3,
  public: {
    amplitude: {
      key: getEnv("NEXT_PUBLIC_AMPLITUDE_KEY"),
    },
  },
  intervals: {
    cron: {
      wallet: parseInterval("CRON_WALLET_INTERVAL_M", 60),
      project: parseInterval("CRON_PROJECT_INTERVAL_M", 60),
      announcementNotification: parseInterval(
        "CRON_ANNOUNCEMENT_NOTIFICATION_INTERVAL_M",
        60
      ),
    },
    gwei: parseInterval("GWEI_UPDATE_INTERVAL_M", 15),
  },
  keywords: {
    ["Claim"]: ["Claim", "Claims", "Claiming"],
    ["Airdrop"]: ["Airdrop"],
    ["Snapshot"]: ["Snapshot"],
    ["Mint"]: ["Public Sale", "Presale", "Mint", "Minting"],
    ["Merch Drop"]: ["Merch"],
  },
  bearerTokens: {
    ["Claim"]: "YC3DUIZKL66QYRKXJ3GUU77JEIDTON4A",
    ["Snapshot"]: "GXOCGW4RPT4AC5BV3TGDTEJNJFHOXMFP",
    ["Mint"]: "W7QJBXOBOYU4IQARHBFFIWUZDW3U3BM7",
    ["Merch"]: "7TXM3TI4CQNAKNCBGCHN6N5D7O47QFM2",
    ["Airdrop"]: "4WTV52UBIR7CZDRVWXY6KG5N5VR6S4C7",
  },
  cron: {
    secret: getEnv("CRON_SECRET"),
    serverUrl: getEnv("CRON_SERVER_URL"),
  },
  payment: {
    apiUrl: getEnv("API_URL"),
    amount: getEnv("PAYMENT_AMOUNT", "0.08"),
    address: getEnv(
      "PAYMENT_ADDRESS",
      "0xaaA18920732D8985501602a6a297886072b80D2c"
    ),
  },
  whitelistedWallet: [
    {
      Name: "DeeZeFi",
      walletAddress: "0xC46Db2d89327D4C41Eb81c43ED5e3dfF111f9A8f",
      ownerProfilePicture:
        "https://lh3.googleusercontent.com/cAiBx5lA10EYkAiwFtxiaJNeVyCujmcTfFqn4clOD7QxYBDkmBMapLzUINBX0kF2lEAZt3SGmKWSp-jW-dkbcscUuQd8FCupUgAQjw=s0",
    },
    {
      Name: "Pranksy",
      walletAddress: "0xD387A6E4e84a6C86bd90C158C6028A58CC8Ac459",
      ownerProfilePicture:
        "https://lh3.googleusercontent.com/TGQuK5B46NboEXf3mQdjnOyh3Co6kesCvLGWtNTBte6SmJBqBPfxNzkLJrncPZStBr8D5eaE8CpGpuIZ7QZX8Fv_5bufJ_Wo8ItvgA=s0",
    },
    {
      Name: "0xWave",
      walletAddress: "0x33569c101562e1fAF5b24581057E5cEE4c8288D7",
      ownerProfilePicture:
        "https://lh3.googleusercontent.com/edxiEEMdJlg82DaNdoAYPnYxY4eAEFifCYgi9Qwz4QWv5RqqAuRnxIsD5oI1NKsQkd4uYpIOONSjSm5ozIoXKv6JbIzrCAoG9zY75Yk=s0",
    },
    {
      Name: "DrewAustin",
      walletAddress: "0x49ca963Ef75BCEBa8E4A5F4cEAB5Fd326beF6123",
      ownerProfilePicture:
        "https://lh3.googleusercontent.com/bie25QYKU3jBqXgYhtbGIYs0gRnGy8FApGGk2KyqBZnqqG42FzTt68U2fWXrRoUmEv4wn_iOehOBJj4Rh-X1PIYrN04_KDHVXaHUxKo=s0",
    },
    {
      Name: "carlini8",
      walletAddress: "0x112E62d5906F9239D9fabAb7D0237A328F128e22",
      ownerProfilePicture:
        "https://lh3.googleusercontent.com/TGQuK5B46NboEXf3mQdjnOyh3Co6kesCvLGWtNTBte6SmJBqBPfxNzkLJrncPZStBr8D5eaE8CpGpuIZ7QZX8Fv_5bufJ_Wo8ItvgA=s0",
    },
    {
      Name: "NFTommo",
      walletAddress: "0x7772881A615cd2d326eBE0475A78f9D2963074b7",
      ownerProfilePicture:
        "https://lh3.googleusercontent.com/TGQuK5B46NboEXf3mQdjnOyh3Co6kesCvLGWtNTBte6SmJBqBPfxNzkLJrncPZStBr8D5eaE8CpGpuIZ7QZX8Fv_5bufJ_Wo8ItvgA=s0",
    },
    {
      Name: "Demo",
      walletAddress: "0x8660FC66cF3E06d86e8013c3b6BE5592A5FA916a",
      ownerProfilePicture:
        "https://lh3.googleusercontent.com/TGQuK5B46NboEXf3mQdjnOyh3Co6kesCvLGWtNTBte6SmJBqBPfxNzkLJrncPZStBr8D5eaE8CpGpuIZ7QZX8Fv_5bufJ_Wo8ItvgA=s0",
    },
  ],
  stakedNFT: [
    { label: "NFTWorlds", handle: "nftworlds" },
    { label: "Metahero", handle: "metahero" },
    { label: "Realms (For Adventures)", handle: "realm" },
    { label: "Metroverse", handle: "metaverse" },
  ],
  noteWorthyThreshold: 2,
  influencers: [
    { influencerName: "pranksy", score: 1 },
    { influencerName: "deezeFi", score: 1 },
    { influencerName: "drewaustin", score: 1 },
    { influencerName: "sheepeth", score: 1 },
    { influencerName: "zerkpass", score: 1 },
    { influencerName: "PopeyesNFTs", score: 1 },
    { influencerName: "PrinceSatoshi1", score: 1 },
    { influencerName: "nftpres", score: 1 },
    { influencerName: "boredape93", score: 1 },
    { influencerName: "OGDfarmer", score: 1 },
    { influencerName: "beijingdou", score: 1 },
    { influencerName: "Zeneca_33", score: 1 },
    { influencerName: "nathanheadphoto", score: 1 },
    { influencerName: "BentoBoiNFT", score: 1 },
    { influencerName: "0xfetty", score: 1 },
    { influencerName: "Degentraland", score: 1 },
    { influencerName: "notthreadguy", score: 1 },
    { influencerName: "boredapewood", score: 1 },
    { influencerName: "CapetainTrippy", score: 1 },
    { influencerName: "sartoshi_nft", score: 1 },
    { influencerName: "PuffYatty", score: 1 },
    { influencerName: "BentoBoiNFT", score: 1 },
    { influencerName: "ohhshiny", score: 1 },
    { influencerName: "NFTLlama", score: 1 },
    { influencerName: "NFTsAreNice", score: 1 },
    { influencerName: "Loopifyyy", score: 1 },
    { influencerName: "MitcheIl", score: 1 },
    { influencerName: "KosherPlug", score: 1 },
    { influencerName: "cr0ssETH", score: 1 },
    { influencerName: "seedphrase", score: 1 },
    { influencerName: "IcedKnife", score: 1 },
    { influencerName: "XCOPYART", score: 1 },
    { influencerName: "tropoFarmer", score: 1 },
    { influencerName: "krybharat", score: 1 },
    { influencerName: "peruggia_v", score: 1 },
    { influencerName: "0xfoobar", score: 1 },
    { influencerName: "gmoneyNFT", score: 1 },
    { influencerName: "DCLBlogger", score: 1 },
    { influencerName: "j1mmyeth", score: 1 },
    { influencerName: "iamDCinvestor", score: 1 },
    { influencerName: "ArtOnBlockchain", score: 1 },
    { influencerName: "farokh", score: 1 },
    { influencerName: "samczsun", score: 1 },
    { influencerName: "Cooopahtroopa", score: 1 },
    { influencerName: "andy8052", score: 1 },
    { influencerName: "Gfunkera86", score: 1 },
    { influencerName: "richerd", score: 1 },
    { influencerName: "alexisohanian", score: 1 },
    { influencerName: "crypto888crypto", score: 1 },
    { influencerName: "HenryTheGrape", score: 1 },
    { influencerName: "ryandcrypto", score: 1 },
    { influencerName: "ZssBecker", score: 1 },
    { influencerName: "OttoSuwenNFT", score: 1 },
    { influencerName: "garyvee", score: 1 },
    { influencerName: "treckex", score: 1 },
    { influencerName: "CozomoMedici", score: 1 },
    { influencerName: "punk9059", score: 1 },
    { influencerName: "mevcollector", score: 1 },
    { influencerName: "Vince_Van_Dough", score: 1 },
    { influencerName: "punk6529", score: 1 },
    { influencerName: "TheNFTAsian", score: 1 },
    { influencerName: "KeyboardMonkey3", score: 1 },
    { influencerName: "ColeThereum", score: 1 },
    { influencerName: "maxnorris__", score: 1 },
    { influencerName: "jumpmanft", score: 1 },
    { influencerName: "xBenJamminx", score: 1 },
    { influencerName: "hunterorrell", score: 1 },
    { influencerName: "dingalingts", score: 1 },
    { influencerName: "Greekdx", score: 1 },
    { influencerName: "LilMoonLambo", score: 1 },
    { influencerName: "zoink", score: 1 },
    { influencerName: "joshbuckley", score: 1 },
  ],
  whitelistedAccounts: [
    "0xB06bD2F164880580CcEF3B00f5Ba1CA05917F50c",
    "0xc971BF0Aa1AAb7EB80bCefa37b56127e96ec3553",
    "0xaAF3Ae8E6d47289f45fAA7B9614bA224E5803c64",
    "0x01B96611A9cf917bffb83FdB5Dc438f8AAEe5537",
    "0x7E0e715BB6779108BCBc4eC6E6A9F49b3D4624fC",
    "0x4CfdDf44C72FF8468E7a34b1ec4dBCa83d602CfE",
    "0x784669570Ca32213d1d64C1592bCD40A1f8d7643",
    "0x911305DB2C47876cBfa11f8D802A36a7fdE1eFbA",
    "0x3bdDd7f0838cB1ffD91586Ba04b17A70D4CB2d0C",
    "0x343F58E988df7c5faf3CA582ec5132d9CC578e4A",
    "0x0C8331aBB2B9D961f08b1737afc3b59fe5e5a4e7",
    "0x8c8a9645cB26CFE334c72f17AC8bA1229d185814",
    "0x890438A1b9a3fBadf24Ec3c5e4B66423cf9ec3B5",
    "0x9aDC98775F7c4c41fB367a52Ea05581358F8ee2B",
    "0xCa7626af64071e7AEE1692757B6bdF6e5213f846",
    "0x15F5Ac7b37b7F56f84135546BF6969F0488745Ae",
    "0x5a598426e1dF716328D4ddDa17cb2166A9b1c352",
  ],
};

export default config;
