import "../styles/globals.css";
import type { AppProps } from "next/app";
import { ChakraProvider, Text } from "@chakra-ui/react";
import useUser, { User, UserContext } from "@lib/hooks/user";
import React, { useEffect, useMemo } from "react";
import { init } from "@amplitude/analytics-browser";
import config from "@src/config";

import "react-datepicker/dist/react-datepicker.css";
import { useRouter } from "next/router";
import { UserPaymentStatusType } from "@lib/types";

import theme from "../theme";
import { DynamicContextProvider } from "@dynamic-labs/sdk-react";
import Loader from "@lib/components/loader";

const IS_PUBLIC = ["/signup", "/wallet"];
const AMPLITUDE_KEY = config.public.amplitude.key;
AMPLITUDE_KEY && init(AMPLITUDE_KEY);

function MyApp({ Component, pageProps }: AppProps) {
  const { user, isPaid, isLoggedIn, isLoading } = useUser();
  const router = useRouter();

  // TODO: Need to change the appLogoUrl
  const dynamicLoginSettings = {
    appLogoUrl:
      "https://pbs.twimg.com/profile_images/1512233782933295106/bwiYfl6n_400x400.jpg",
    appName: "Scottie",
    environmentId: process.env.NEXT_PUBLIC_DYNAMIC_LOGIN_KEY ?? "",
  };

  const isProtected = useMemo(() => {
    if (IS_PUBLIC.some((route) => router.pathname.startsWith(route))) {
      return false;
    }

    if (isPaid) {
      return false;
    }

    return true;
  }, [isPaid, router.pathname]);

  useEffect(() => {
    if (!isLoading && !isPaid && isProtected) {
      router.push("/signup");
    }
  }, [user, isProtected, router, isLoading, isPaid]);

  const message =
    !user || isLoading
      ? "Loading"
      : isProtected
      ? user.id === null
        ? "Unauthenticated"
        : user.paymentStatus !== UserPaymentStatusType.Paid
        ? `Payment status: ${user.paymentStatus}`
        : null
      : null;

  return (
    <DynamicContextProvider settings={dynamicLoginSettings}>
      <ChakraProvider theme={theme}>
        {user ? (
          <UserContext.Provider value={{ user, isPaid, isLoggedIn, isLoading }}>
            {!isProtected && <Component {...pageProps} />}
            {(user?.paymentStatus === UserPaymentStatusType.Unpaid ||
              user?.paymentStatus === UserPaymentStatusType.Pending) && (
              <Text>{JSON.stringify(user)}</Text>
            )}
          </UserContext.Provider>
        ) : (
          <Loader message={message} />
        )}
      </ChakraProvider>
    </DynamicContextProvider>
  );
}

export default MyApp;
