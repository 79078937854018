export enum ReminderType {
  Gwei = "Gwei",
  Time = "Time",
}

export enum ReminderStatusType {
  Pending = "pending",
  Completed = "completed",
}

export enum NotificationStatusType {
  Pending = "pending",
  Sent = "sent",
  Error = "error",
}

export enum UserPaymentStatusType {
  Unpaid = "unpaid",
  Pending = "pending",
  Paid = "paid",
}

export enum NotificationPreferenceType {
  All = "all",
  TimeSensitive = "time-sensitive",
  SmartFilter = "smart-filter",
  None = "none",
}

export enum UpgradeSteps {
  Initial = "Initial",
  Pending = "Pending",
  Success = "Success",
  Email = "Email",
  Wallet = "Wallet",
}

export enum CronJobStatus {
  Pending = "Pending",
  Running = "Running",
  Completed = "Completed",
  Failed = "Failed",
  Stale = "Stale",
}

export enum NotificationType {
  Reminder = "reminder",
  NewAnnouncement = "new-announcement",
}

export enum ProjectType {
  Project = "project",
  User = "user",
  None = "none",
}

export enum AmplitudeEvents {
  PaymentInitiated = "payment initiated",
  PaymentSuccessful = "payment successful",
  WalletAdded = "wallet added",
  ManuallyTrackedProject = "manually tracked project",
  DashboardVisited = "dashboard visited",
  SettingsVisited = "settings visited",
  EmailAdded = "email added",
  ReminderCreated = "reminder created",
  WalletConnected = "wallet connected",
}
