import useSWR, { SWRConfiguration } from "swr";

export const get = async (endpoint: string) => {
  const res = await fetch(endpoint);

  const data = await res.json();
  if (res.status >= 300) {
    throw new Error(data.error ?? "unknown error");
  }
  return data;
};

export const post = async <T>(endpoint: string, data: T) => {
  const res = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return res.json();
};

export const patch = async <T>(endpoint: string, data: T) => {
  const res = await fetch(endpoint, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return res.json();
};

export const dismiss = async <T>(endpoint: string, data: T) => {
  const res = await fetch(endpoint, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return res.json();
};

export default function useAPI<T>(
  endpoint: string | null,
  config: SWRConfiguration = {}
) {
  return useSWR<T>(endpoint, get, config);
}
