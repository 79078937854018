import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: "Inter, sans-serif",
    body: "Inter, sans-serif",
  },
  components: {
    Modal: {
      baseStyle: () => ({
        dialog: {
          bg: "transparent",
        },
      }),
    },
  },
});

export default theme;
