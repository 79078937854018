import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
const Loader = ({ message }: { message: string | null }) => {
  return (
    <Flex
      height={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}
    >
      <Box>
        <Spinner />
      </Box>
      <Box>
        <Text color={"#454545"}>{message}</Text>
      </Box>
    </Flex>
  );
};

export default Loader;
