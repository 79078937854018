import { UserPaymentStatusType } from "@lib/types";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import useAPI from "./api";

export type User = {
  id: number | null;
  primaryWallet?: string;
  email?: string;
  nonce?: string;
  paymentStatus?: UserPaymentStatusType;
  transactions?: Array<{ hash: string; status: string }>;
};

export type UserContextType = {
  user: User;
  isLoggedIn: boolean;
  isPaid: boolean;
  isLoading: boolean;
};

export const UserContext = createContext<UserContextType>({
  user: { id: null },
  isLoading: true,
  isLoggedIn: false,
  isPaid: false,
});

export function useUserContext() {
  return useContext(UserContext);
}

export default function useUser(): Omit<UserContextType, "user"> & {
  user?: User;
} {
  const [refreshInterval, setRefreshInterval] = useState(0);

  const { data, error } = useAPI<User>("/api/auth", {
    refreshInterval,
  });

  const user = useMemo<User | undefined>(
    () => (error ? { id: null } : data),
    [data, error]
  );

  console.log("user", { user });

  useEffect(() => {
    if (!user?.paymentStatus) return;
    setRefreshInterval(
      user?.paymentStatus === UserPaymentStatusType.Pending ? 500 : 0
    );
  }, [user]);

  return {
    user,
    isLoggedIn:
      !!user?.id && user?.paymentStatus !== UserPaymentStatusType.Unpaid,
    isPaid: user?.paymentStatus === UserPaymentStatusType.Paid,
    isLoading: !data && !error,
  };
}
